
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class dept extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }
  tb = {
    page: 1,
    pageSize: 10
  }
  pageChange({ currentPage, pageSize }) {
    this.tb.page = currentPage
    this.tb.pageSize = pageSize
  }
  depts_all_data = []

  depts_total = 0
  depts_search_data = {
    name: ''
  }

  rule_depts_search_data = {}

  depts_add_dialog = {
    open: false,
    title: '新增一级部门'
  }

  depts_add_data = {
    name: '',
    description: ''
  }

  rule_depts_add_data = {
    name: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
  }

  depts_del_dialog = {
    open: false,
    title: '删除'
  }

  depts_del_data = {
    id: 0
  }

  depts_edit_dialog = {
    open: false,
    title: '编辑'
  }

  depts_edit_data = {
    id: 0,
    name: '',
    description: '',
    status: 0
  }

  rule_depts_edit_data = {
    name: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
  }

  depts_edit1_dialog = {
    open: false,
    title: '新增子部门'
  }

  depts_edit1_data = {
    id: 0,
    name: '',
    description: ''
  }

  rule_depts_edit1_data = {
    name: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
  }

  status_12 = new Map<any, string>([
    [1, '启用'],
    [2, '未启用']
  ])

  depts_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      name: this.depts_search_data.name
    }
    Api.http_deptdepts0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.depts_all_data = res.data.data
        this.depts_total = res.data.total
      }
    })
  }
  depts_add_init(row) {
    this.depts_add_dialog.open = true
  }
  depts_add_cancle() {
    this.depts_add_dialog.open = false
  }
  depts_add_ok() {
    this.$refs.ref_depts_add_data.validate((valid) => {
      if (valid) {
        const postData = {
          name: this.depts_add_data.name,
          description: this.depts_add_data.description
        }
        Api.http_deptdepts1(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.depts_add_dialog.open = false
            this.depts_get_all_data(
              (this.tb.page - 1) * this.tb.pageSize,
              this.tb.pageSize
            )
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  depts_del_init(row) {
    this.depts_del_data.id = row.id
    this.depts_del_dialog.open = true
  }
  depts_del_cancle() {
    this.depts_del_dialog.open = false
  }
  depts_del_ok() {
    const postData = {
      id: this.depts_del_data.id
    }
    Api.http_deptdepts2(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.depts_del_dialog.open = false
        this.depts_get_all_data(
          (this.tb.page - 1) * this.tb.pageSize,
          this.tb.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
      }
    })
  }
  depts_edit_init(row) {
    Api.http_deptdeptsget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.depts_edit_data.id = row.id
        this.depts_edit_data.name = res.data.name
        this.depts_edit_data.description = res.data.description
        this.depts_edit_data.status = res.data.status
        this.depts_edit_dialog.open = true
      }
    })
  }
  depts_edit_cancle() {
    this.depts_edit_dialog.open = false
  }
  depts_edit_ok() {
    this.$refs.ref_depts_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.depts_edit_data.id,
          name: this.depts_edit_data.name,
          description: this.depts_edit_data.description,
          status: this.depts_edit_data.status
        }
        Api.http_deptdepts5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.depts_edit_dialog.open = false
            this.depts_get_all_data(
              (this.tb.page - 1) * this.tb.pageSize,
              this.tb.pageSize
            )
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  depts_edit1_init(row) {
    this.depts_edit1_dialog.title = row.name
    Api.http_deptdeptsget6({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.depts_edit1_data.id = row.id
        this.depts_edit1_data.name = res.data.name
        this.depts_edit1_data.description = res.data.description
        this.depts_edit1_dialog.open = true
      }
    })
  }
  depts_edit1_cancle() {
    this.depts_edit1_dialog.open = false
  }
  depts_edit1_ok() {
    this.$refs.ref_depts_edit1_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.depts_edit1_data.id,
          name: this.depts_edit1_data.name,
          description: this.depts_edit1_data.description
        }
        Api.http_deptdepts6(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.depts_edit1_dialog.open = false
            this.depts_get_all_data(
              (this.tb.page - 1) * this.tb.pageSize,
              this.tb.pageSize
            )
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  depts_init() {
    this.depts_get_all_data(
      (this.tb.page - 1) * this.tb.pageSize,
      this.tb.pageSize
    )
  }
  created() {
    this.depts_init()
  }
}
